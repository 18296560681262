import React from "react";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import video from "../../assets/video/events in poland 2cz.mp4";
import poster from "../../assets/video/poster.png";

import styled from "styled-components";

const VideoPlayer = props => {
    // const context = useContext(AuthContext)

    return (
        <Container>
            <Player playsInline poster={poster} src={video} />
        </Container>
    );
};

export default VideoPlayer;

const Container = styled.div`
    padding: 20px 0;
`;
