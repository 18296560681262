import React, { useContext, useEffect, useState } from "react";
import "../assets/styles/eventspage.scss";
import { Link } from "react-router-dom";
import { getToken } from "../api/api";
import axios from "axios";
import Context from "../context";
import { dateConverter } from "../containers/date";
import Square from "../components/EventsPage/Squqre";
import Loading from "../components/Loading/Loading";
//import { now } from "moment";

const EventsPage = (props) => {
    const context = useContext(Context);
    const { dispatch } = useContext(Context);
    const [content, setContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getToken(process.env.REACT_APP_USER_DATA_KEY).then((res) => {
            let arrOfEvent = [];
            axios({
                method: "get",
                url: process.env.REACT_APP_EVENTS_DETAILS,
                headers: {
                    Authorization: res.data.token,
                },
            })
                .then((res) => {
                    res.data.map((event) => {
                        if (
                            context.state.startEventDate === null &&
                            context.state.endEventDate === null
                        ) {
                            let endDate = new Date(
                                event.event.to_date,
                            ).getTime();
                            let actDate = new Date().getTime();
                            return endDate > actDate
                                ? arrOfEvent.push(event)
                                : null;
                        } else {
                            return arrOfEvent.push(event);
                        }
                    });
                    dispatch({ type: "GET_EVENTS", payload: arrOfEvent });
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
        if (props.location.state !== undefined) {
            dispatch({
                type: "SEARCH_EVENT_BY_NAME",
                payload: props.location.state.eventName,
            });
        } else {
            dispatch({ type: "SEARCH_EVENT_BY_NAME", payload: "" });
        }
    }, []);

    useEffect(() => {
        let events = [];
        let selEvents = [];

        const selStartDate =
            context.state.startEventDate !== null
                ? context.state.startEventDate
                : "01-01-1900";
        const selEndDate =
            context.state.endEventDate !== null
                ? context.state.endEventDate
                : "01-01-2100";

        context.state.events.map((event) => {
            return event.event.type !== "Hotel" ? events.push(event) : null;
        });

        if (selEndDate === "01-01-2100" && selStartDate !== "01-01-1900") {
            events.map((event) => {
                return new Date(selStartDate) < new Date(event.event.to_date)
                    ? selEvents.push(event)
                    : null;
            });
        } else if (
            selStartDate === "01-01-1900" &&
            selEndDate !== "01-01-2100"
        ) {
            events.map((event) => {
                return new Date(selEndDate) > new Date(event.event.from_date)
                    ? selEvents.push(event)
                    : null;
            });
        } else if (
            selEndDate !== "01-01-2100" &&
            selStartDate !== "01-01-1900"
        ) {
            events.map((event) => {
                return new Date(event.event.from_date) >=
                    new Date(selStartDate) &&
                    new Date(event.event.to_date) <= new Date(selEndDate)
                    ? selEvents.push(event)
                    : null;
            });
        } else {
            selEvents = events;
        }

        let filteredItems = selEvents;
        const filtered = filteredItems.filter((item) => {
            return (
                item.event.title
                    .toLowerCase()
                    .indexOf(context.state.searchEventByName.toLowerCase()) !==
                -1
            );
        });
        const content = filtered.map((event, index) => {
            const start = dateConverter(event.event.from_date);
            const end = dateConverter(event.event.to_date);

            const date = start === end ? start : start + " - " + end;

            //if (selStartDate <= start && selEndDate >= start) {
            return (
                <Square
                    key={index}
                    title={event.event.title}
                    date={date}
                    id={event.event.id}
                    img={event.eventDetails}
                />
            );
        });
        setContent(content);
    }, [context.state.events, context.state.searchEventByName]);
    useEffect(() => {}, [
        context.state.endEventDate,
        context.state.startEventDate,
    ]);
    if (isLoading) {
        return <Loading />;
    } else {
        return (
            <div className="container events-page">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-2">
                        <div className="container d-flex flex-wrap">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                All events
                            </li>
                        </div>
                    </ol>
                </nav>
                <div className="event-banner d-flex align-items-end mb-5">
                    <div className="event-search d-flex align-items-center justify-content-center">
                        <div className="col-sm-5 text-center">
                            <h1>List of events</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">{content} </div>
                </div>
            </div>
        );
    }
};

export default EventsPage;
