import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { getContentBpower } from "../../api/api";
import Context from "../../context";
import { useCookies } from "react-cookie";

import pl from "../../assets/images/flags/pl.png";
import uk from "../../assets/images/flags/uk.png";
import de from "../../assets/images/flags/de.png";
import ar from "../../assets/images/flags/ar.png";
import nr from "../../assets/images/flags/nr.png";
import LoadingBanner from "../Loading/LoadingBanner";

const Language = (props) => {
    const { dispatch } = useContext(Context);
    const context = useContext(Context);
    const [language, setLanguage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [cookies, setCookie] = useCookies();
    // locations odpowiada za id strony dodanej w module www zgodnie z opisami przy cyferkach
    const locations = [12, 13, 14, 15, 16, 17];
    // 12 - homepage
    // 13 - about-us
    // 14 - news
    // 15 - contact
    // 16 - footer
    // 17 - navbar

    useEffect(() => {
        locations.map((location) => {
            // getContentBpower znajduje się w pliku api odpowiada za pobieranie kontentu danej strony.
            // Czyli przekazujemy numer id strony z modułu www oraz język a następnie funkcja pobiera wszyskie dostępne teksty dla danej strony w wybranym języku
            const getContent = getContentBpower(location, language);
            // tutaj otrzymujemy odpowiedź z funkcji getContentBpower z tekstami dla odpowiedniej strony
            getContent.then((res) => {
                switch (location) {
                    // każdy case to id strony
                    case 12:
                        // dispatch przesyła odpowiedź do reducera a następnie reducer uaktualnia stan tekstów
                        dispatch({
                            type: "HOMEPAGE_CONTENT",
                            payload: res,
                        });
                        break;
                    case 13:
                        dispatch({ type: "ABOUTUS_CONTENT", payload: res });
                        break;
                    case 14:
                        dispatch({ type: "NEWS_CONTENT", payload: res });
                        break;
                    case 15:
                        dispatch({ type: "CONTACT_CONTENT", payload: res });
                        break;
                    case 16:
                        dispatch({ type: "FOOTER_CONTENT", payload: res });
                        break;
                    case 17:
                        dispatch({ type: "NAVBAR_CONTENT", payload: res });
                        break;
                    default:
                        return null;
                }
            });
            return null;
        });
        if (cookies.eventsLanguage) {
            dispatch({ type: "SET_LANGUAGE", payload: cookies.eventsLanguage });
            setLanguage(cookies.eventsLanguage);
        } else {
            dispatch({ type: "SET_LANGUAGE", payload: "English" });
            setLanguage("English");
        }
    }, [language]);

    useEffect(() => {
        if (window.location.pathname === "/en") {
            setCookie("eventsLanguage", "English");
            window.location.assign("/");
        }
        if (window.location.pathname === "/pl") {
            setCookie("eventsLanguage", "Polish");
            window.location.assign("/");
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [context.state.homepage]);

    const changeLanguage = (lang) => {
        setCookie("eventsLanguage", lang);
        setIsLoading(true);
        window.location.reload();
    };

    return (
        <>
            <Div className="languages">
                <Img src={uk} onClick={() => changeLanguage("English")} />
                <Img src={pl} onClick={() => changeLanguage("Polish")} />
                <Img src={de} onClick={() => changeLanguage("German")} />
                <Img src={ar} onClick={() => changeLanguage("Arabic")} />
                <Img src={nr} onClick={() => changeLanguage("Norwegian")} />
            </Div>
            {isLoading ? <LoadingBanner /> : null}
        </>
    );
};

export default Language;

const Div = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
`;

const Img = styled.img`
    width: 30px;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.05);
    }
`;
